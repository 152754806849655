import { apiService } from './api.service';

export const customerService = {
    getById,
    remove,
    update,
    add,
    search,
    getMessages,
    sendMessage,
    addNote,
    sendEmail
}

function getById(id, options) {
    return apiService.getItem("customers", id, options);
}

function search(options) {
    return apiService.getItems("customers", options);
}

function remove(id) {
    return apiService.deleteItem("customers", id);
}

function update(customer) {
    return apiService.updateItem("customers", customer, true);
}

function add(customer) {
    return apiService.addItem("customers", customer);
}

function getMessages(customerId, options) {
    return apiService.getItems(`chat/customer/${customerId}`, options);
}
function sendMessage(customerId, message) {
    return apiService.addItem(`chat/customer/${customerId}`, message);
}
function addNote(customerId, note) {
    return apiService.addItem(`customers/${customerId}/notes`, { content: note });
}

function sendEmail(customerId, email) {
    return apiService.addItem(`customers/${customerId}/email`, email);
}