import { page } from "../_constants";

export const isSystemAdmin = (user) => {
    return (user.accessLevel > 0);
}

export const isAdmin = (user) => {
    var user_role = getRole(user);
    return (user_role == Role.TenantAdmin || user_role == Role.TenantManager);
}

export const Role = {
    None: 0,
    JourneyCreator: 1,
    ContentEditor: 2,
    TenantManager: 3,
    TenantAdmin: 4,
    JourneyUser: 5,
    BrandAmbassador: 6,
    ShopAssistant: 7
}

export function getRoleName(role) {
    switch (role) {
        case Role.None: return "Not Logged In";
        case Role.BrandAmbassador: return "Brand Ambassador";
        case Role.ContentEditor: return "Content Editor";
        case Role.JourneyCreator: return "User";
        case Role.JourneyUser: return "Journey User";
        case Role.ShopAssistant: return "Shop Assistant";
        case Role.TenantAdmin: return "Admin";
        case Role.TenantManager: return "Manager";
    }
}



export const getRole = (user) => {
    if (user && user.activeTenant) {
        return user.activeTenant.role;
    }
    return undefined;
}

export const permissionAction = {
    saveOnly: 1,
    readOnly: 2,
    saveAndDelete: 0
}

export function getUserPermission(role, listSelector) {
    //Role       

    const pages = [
        page.Journey, page.Product, page.ProductType, page.Fulfilment, page.Brand,
        page.Media, page.AssetSource, page.Customer, page.Tags, page.SectionTemplate,
        page.User, page.Orders, page.Consignment, page.Shop, page.Search,
        page.Calls];

    const pageIndex = pages.indexOf(listSelector);

    switch (role) {
        case Role.SuperAdmin:
        case Role.SystemAdmin:
        case Role.TenantAdmin:
        case Role.TenantManager: return permissionAction.saveAndDelete;
        case Role.ContentEditor: return [
            permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly,
            permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly,
            permissionAction.saveOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly,
            permissionAction.readOnly][pageIndex];
        case Role.JourneyCreator: return [
            permissionAction.saveAndDelete, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly,
            permissionAction.readOnly, permissionAction.readOnly, permissionAction.saveOnly, permissionAction.readOnly, permissionAction.readOnly,
            permissionAction.saveOnly, permissionAction.saveOnly, permissionAction.saveAndDelete, permissionAction.readOnly, permissionAction.readOnly,
            permissionAction.saveOnly][pageIndex];
        case Role.BrandAmbassador: return [
            permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly,
            permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly,
            permissionAction.saveOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly, permissionAction.readOnly,
            permissionAction.readOnly][pageIndex];
    }
}