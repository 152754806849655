import React, { useEffect, useRef, useState } from 'react';
import { tenantService } from '../_services';
import { addModalActions, tableActions, userActions } from '../_actions';
import ImageComponent from '../Components/Layout/AssetComponent';
import { Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import "../Styles/tenantsPage.scss";
import ReactPaginate from 'react-paginate';
import { page } from '../_constants';
import { PagingList, State, TableUpdates, Tenant } from '../_types';
import { useTranslation } from 'react-i18next';

export interface TenantPageProps {
    tenantSelected?: ((t: Tenant) => void);
    showCreateNew?: boolean;
    redirect?: boolean;
}

export default function TenantsPage({ tenantSelected, showCreateNew = true, redirect }: TenantPageProps) {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [query, setQuery] = useState("");
    const stateOption = useSelector<State, string>(state => state.table.option);
    const staleData = useSelector<State, string>(state => state.table.stale);
    const updates = useSelector<State, TableUpdates>(state => state.table.updates);
    const [pages, setPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [filterText, setFilterText] = useState('');

    function getTableData() {
        tenantService.searchTenants({ searchField: 'name', searchValue: query, page: pageNumber, pageSize: 30, include: ['small'] })
            .then(formatData)
    }

    function formatData(apiData: PagingList<Tenant>) {
        setTenants(apiData.items);
        let pages = Math.ceil(apiData.count / 30);
        setPages(pages);
    }

    useEffect(() => {
        getTableData();
    }, [query, pageNumber]);

    useEffect(() => {
        if (page.Tenant == stateOption) {
            let result = tenants;
            updates.removed.forEach((id: number) => {
                result = result.filter(row => row.id !== id);
            });
            updates.added.forEach((row: Tenant) => {
                result.unshift(row);
            });
            tableActions.acknowledgeUpdate()(dispatch);
            setTenants(result);
        }
    }, [staleData]);

    function selectTenant(tenant: Tenant) {
        if (!tenantSelected)
            userActions.changeTenancy(tenant, redirect)(dispatch);
        else
            tenantSelected(tenant);
    }

    function createNewTenant() {
        tableActions.changeTableOption(page.Tenant)(dispatch);
        addModalActions.openModal(page.Tenant)(dispatch);
    }

    const onPageClick = (value: { selected: number }) => {
        let pageVal = value.selected;
        pageVal++;
        setPageNumber(pageVal);
    };

    function handleClear() {
        setFilterText("");
        setPageNumber(1);
        setQuery("");
    };

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        setFilterText(value);
        setQuery(value);
        setPageNumber(1);
    }

    return (
        <div>
            <div className='page-header'>
                <div className='page-title'>{t("page.create-tenant")}</div>
                <div className='page-subtitle'>{t("page.subtitle.create-tenant")}</div>
            </div>
            <div className="tenants-page">
                <div className="search-tenants">
                    <form id="tenants-search" className="tenants-search">
                        <div className="search">
                            <i className="far fa-search"></i>
                            <input
                                placeholder={t("tenantsPage.search-companies")}
                                className="input form searchTerm form-control"
                                value={filterText}
                                onChange={handleChange}
                            />
                            <button type="button" className="input-group-prepend" onClick={handleClear}>
                                <i className="fa fa-times"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div className="tenants-container">
                    {showCreateNew ?
                        <div className="company-card">
                            <Button className="tenant-badge add-company" onClick={createNewTenant}>
                                <label><i className="fal fa-plus"></i></label>
                                <label>{t("tenantsPage.new-company")}</label>
                            </Button>
                        </div>
                        : null}
                    {tenants.map((t, i) => (
                        <div key={i} className='company-card'>
                            <Button className="tenant-badge" onClick={() => selectTenant(t)}>
                                {t.small ? <ImageComponent asset={t.small} width={100} height={100}></ImageComponent>
                                    :
                                    <i className="fas fa-city"></i>}
                            </Button>
                            <label>{t.name}</label>
                        </div>
                    ))}
                </div>
                {pages > 1 && <div id="react-paginate" className="">
                    <ReactPaginate
                        activeClassName={'item active '}
                        breakClassName={'item break-me '}
                        breakLabel={'...'}
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        pageCount={pages}
                        forcePage={pageNumber - 1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={onPageClick}
                        containerClassName={'pagination'}
                        disabledClassName={'disabled-page'}
                    />
                </div>}
            </div>
        </div>
    );
}
