import React, { useState, useEffect } from 'react';
import { FormGroup } from 'react-bootstrap';
import TextInput from '../../EditingComponents/TextInput';
import { tenantService } from '../../../_services';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';


function AddCustomer({ addInputToParent, submitted, setIsDisabled }) {
    const { t } = useTranslation("common")
    useEffect(() => {
        setIsDisabled(true);
    }, []);

    const [nameEmpty, setNameEmpty] = useState(false);
    const [phoneNumberOk, setPhoneNumberOk] = useState(true);
    const [emailOk, setEmailOk] = useState(true);
    const [inputs, setInputs] = useState({
        name: '',
        email: null,
        phone: null,
    });
    const user = useSelector((state) => state.authentication.user);

    useEffect(() => {
        tenantService.getTenantById(user.activeTenant.id, 'options').then((t) => {
            if (t.options?.allowPrivateCustomers) {
                setInputs((inputs) => ({ ...inputs, isPrivate: true, salesAssociateId: user.id }));
            }
        });
    }, []);

    useEffect(() => {
        const nameEmpty = !inputs.name;
        const phoneEmpty = !(inputs.phone && isValidPhoneNumber(inputs.phone));
        const emailEmpty = !inputs.email;
        const invalid = nameEmpty || (phoneEmpty && emailEmpty);
        setIsDisabled(invalid);
        if (!invalid) {
            addInputToParent(inputs);
        }
    }, [inputs]);

    const { name, email, phone } = inputs;

    function nameChanged(e) {
        const { value } = e.target;
        setInputs((inputs) => ({ ...inputs, name: value }));
        setNameEmpty(!value);
    }

    function emailChanged(e) {
        const { value } = e.target;
        setInputs((inputs) => ({ ...inputs, email: value }));
        setEmailOk(!!value);
        setPhoneNumberOk(inputs.phone && isValidPhoneNumber(inputs.phone));
    }

    function handlePhoneChange(value) {
        const newPhone = value ? value.toString() : '';
        setInputs((inputs) => ({ ...inputs, phone: newPhone }));
        setPhoneNumberOk(newPhone && isValidPhoneNumber(newPhone));
        //the default state for ok is true, to avoid error messages before the user starts to enter data
        //so when we update the phone number or email, we need to update the other ok state
        setEmailOk(!!inputs.email);
    }

    return (
        <div className="new-customer-form">
            <FormGroup row="true">
                <span className="ml-auto align-self-start small">{t("modals.fields-mark-as")} <span className="text-danger">*</span> {t("modals.are-required")}</span>
                <TextInput value={name} name="name" handleChange={nameChanged} submitted={submitted} important={true} />
                {nameEmpty && <div className="text-danger">{t("modals.name-is-empty")}</div>}
            </FormGroup>

            <FormGroup row="true">
                <TextInput value={email} name="email" handleChange={emailChanged} submitted={submitted} placeholder="Email" />
                <label>{t("modals.phone")}</label>
                <PhoneInput
                    international={true}
                    withCountryCallingCode={true}
                    placeholder="Phone Number"
                    value={phone}
                    onChange={handlePhoneChange}
                />
                {(!phoneNumberOk && !emailOk) && <div className="text-danger">{t("modals.please-enter-a-valid-phone-number-or-email-address")}</div>}
            </FormGroup>
        </div>
    );
}

export default AddCustomer;